<template>
  <el-dialog :visible="visiable" @close="closeDialog" append-to-body width="1100px" class="mapping-alert">
    <template slot="title">
      <div class="left-title">
        <span class="title">关联应用</span>
        <el-tooltip popper-class="relat-temp-tip" placement="bottom-start">
          <template slot="content">
            <div>生成状态为"未提交"状态，则生成建模数据，如生成为"未提交"，则需判断，生成的建模数据是否有必填项未填写，如果有则提示"生成失败，有必填项未输入"。</div>
          </template>
          <en-icon name="tishi" color="#F7C027" size="small"></en-icon>
        </el-tooltip>
      </div>
    </template>
    <div class="content">
      <div
        class="item "
        @click="
          curMappingId = item.id;
          curMapping = item;
        "
        :class="curMappingId == item.id ? 'cur' : ''"
        v-for="(item, index) in mappingList"
        :key="index"
      >
        <div class="item-bg">
          <en-icon style="color: #ffffff;" size="16" name="yingsheguize"></en-icon>
        </div>
        <span>{{ item.name }}</span>
      </div>
    </div>
    <template slot="footer">
      <en-button style="font-size: 14px;" :loading="btnLoading" @click="handleRelatClick">确定</en-button>
    </template>
  </el-dialog>
</template>

<script>
import { mailDetail } from "@/api/mailBox/MailDetail/index.js";
import { newTabAddBusinessData } from "@/components/businessDetail/helper";

export default {
  name: "MailDetail",
  props: {
    // 邮件的id
    id: {
      type: String,
      required: true,
      default: ""
    },
    show: {
      type: Boolean,
      default: false
    },
    mappingList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      visiable: false,
      curMappingId: "",
      curMapping: {},
      // 按钮load
      btnLoading: false
    };
  },
  mounted() {
    this.visiable = this.show;
  },
  methods: {
    // 关闭回调
    closeDialog() {
      this.$emit("toShow", false);
    },
    async handleRelatClick() {
      if (this.curMappingId) {
        this.btnLoading = true;
        const p = {
          id: this.curMappingId,
          mailIds: this.id
        };

        try {
          const res = await mailDetail.executeMailBtn(p);
          // 映射生成失败
          if (res && res.errorMappingId) {
            const route = newTabAddBusinessData({
              mailId: this.id,
              businessType: res.templateType,
              templateId: this.curMapping.templateId,
              mailBtnId: this.curMappingId
            });
            const mailRouter = this.$router.resolve(route);
            window.open(mailRouter.href, "_black");
          } else {
            // 映射生成成功 关闭弹框刷新数据
            this.$message({
              message: "操作成功",
              type: "success"
            });
            this.$emit("toShow", true);
          }
          this.btnLoading = false;
        } catch (error) {
          this.btnLoading = false;
        }
      } else {
        this.$message({
          message: "请先选择映射模板",
          type: "warning"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-dialog__wrapper /deep/.el-dialog__body {
  background: #ffffff !important;
  padding: 8px 20px !important;
  height: auto !important;
}
.mapping-alert {
  .left-title {
    > span {
      vertical-align: middle;
    }
    .en-icon {
      vertical-align: middle;
      margin-left: 5px;
    }
  }
}
.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  .item {
    position: relative;
    display: flex;
    min-width: 520px;
    height: 60px;
    border: 1px solid #e8ecf2;
    border-radius: 5px;
    padding-left: 14px;
    margin-bottom: 16px;

    align-items: center;
    cursor: pointer;
    &:nth-child(odd) {
      /*奇数行*/
      margin-right: 16px;
    }
    .item-bg {
      background: #3e90fe;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      margin-right: 13px;
    }
  }
  .cur {
    background: url("../../assets/gou-bg.png") no-repeat;
  }
}
</style>
