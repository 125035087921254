<!--
 * @Author: pengyu
 * @Date: 2021-03-04 11:47:20
 * @LastEditors: 梁平贤
 * @LastEditTime: 2021-03-31 09:55:56
 * @Description: file content
-->
<template>
  <div class="btn-item">
    <el-popover popper-class="btn-item-popover" v-if="btnData.list.length" placement="bottom" trigger="hover" v-model="btnVisible">
      <div class="btn-list-wrap">
        <div class="btn-list-item" v-for="(btn) in btnData.list" :key="btn.id" @click="handleBtnClick(btn.id)">
          <en-button
            type="text"
            :icon="getBtnIcon(btn.id)"
            :iconSize="16"
            :iconColor="btn.id==='read'?'#26c393':'#f76b6b'"
          >{{btn.name}}</en-button>
        </div>
      </div>
      <en-button
        slot="reference"
        :icon="getBtnIcon(btnData.id)"
        :iconSize="16"
        :iconColor="getBtnIconColor(btnData.id)"
        type="text"
      >
        <span>{{btnData.name}}</span>
        <i class="iconfont iconfanhui"></i>
      </en-button>
    </el-popover>
    <en-button
      v-else
      type="text"
      :icon="getBtnIcon(btnData.id)"
      :iconSize="16"
      :iconColor="getBtnIconColor(btnData.id)"
      @click="handleBtnClick(btnData.id)"
    >{{btnData.name}}</en-button>
  </div>
</template>

<script>
export default {
  name: "ButtonItem",
  props: {
    btnData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      btnVisible: false
    };
  },
  inject: ["$mailList"],
  methods: {
    /**
     * @description 获取按钮icon
     */
    getBtnIcon(id) {
      switch (id) {
        case "delete": // 删除
          return "shanchu";
        case "deleteComplete": // 彻底删除
          return "chedishanchu";
        case "tag": // 标识
          return "biaoji";
        case "move": // 移动
          return "yidong";
        case "read": // 已读
          return "yidu";
        case "noread": // 未读
          return "yijianyuedu";
        case "relat":
          return "guanlianyingyong";
        default:
          return "";
      }
    },
    /**
     * @description 获取按钮iconColor
     */
    getBtnIconColor(id) {
      switch (id) {
        case "delete":
        case "deleteComplete":
          return "#f76b6b";
        case "tag":
          return "#ffad2c";
        case "read":
          return "#26c393";
        case "noread":
          return "#ffad2c";
        case "move":
          return "#26c393";
        case "relat":
          return "#3e90fe";
        default:
          return "";
      }
    },
    /**
     * @description 按钮点击
     */
    handleBtnClick(id) {
      const list = this.$mailList.mailList;
      const checkedData = list.filter((itm) => itm.checked);
      if (checkedData.length === 0) { // 无勾选邮件
        this.$message({
          message: "请勾选邮件",
          type: "warning"
        });
        return;
      }
      const ary = [];
      checkedData.forEach((itm) => {
        ary.push(itm.id);
      });
      const ids = ary.join(",");
      switch (id) {
        case "delete":
          {
            this.$mailList.deleteMail(ids, 1);
            break;
          }
        case "deleteComplete":
          {
            this.$mailList.deleteMail(ids, 2);
            break;
          }
        case "read":
          {
            this.$mailList.updateMailRead(ids, 0);
            break;
          }
        case "noread":
          {
            this.$mailList.updateMailRead(ids, 1);
            break;
          }
        case "relat":
          {
            // 打开映射 弹框
            // 获取选择的ids
            if (this.$mailList.mappingList.length) {
              this.$mailList.mappingIds = ids;
              this.$mailList.addMappingflg = true;
              // this.$mailList.relatVisible = true;
            } else {
              this.$message({
                message: "无可用的映射配置，请联系管理员",
                type: "warning"
              });
            }
            break;
          }
        case "move":
          {
            this.$mailList.moveMail(ids);
            break;
          }
        default:
          break;
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.btn-item{
  & > .en-button{
    margin-left: 20px;
  }
  & /deep/ .en-button{
    margin-left: 20px;
    .iconfanhui{
      font-size: 10px !important;
      color: #a9b5c6;
      display: inline-block;
      margin-left: 2px;
      transform: rotate(-90deg);
    }
  }
}
</style>
<style lang="scss">
.btn-item-popover{
  padding: 2px 0;
  .btn-list-wrap{
    .btn-list-item{
      cursor: pointer;
      text-indent: 20px;
      font-size: 12px;
      &:hover{
        background: #4895df !important;
        color: #ffffff !important;
        .en-button{
          color: #ffffff !important;
        }
        .en-icon-gap{
          fill: rgb(255,255,255) !important;
        }
      }
    }
  }
}
</style>
