<!--
 * @Author: pengyu
 * @Date: 2021-03-04 11:34:00
 * @LastEditors: pengyu
 * @LastEditTime: 2021-05-06 17:57:58
 * @Description: file content
-->
<template>
 <div class="btn-mod">
    <button-item v-for="(itm, idx) in btnArray" :key="idx" :btnData="itm" v-show="itm.show"></button-item>
 </div>
</template>

<script>
import { cloneDeep } from "lodash";
import buttonItem from "./item";
/* eslint-disable */
export default {
  name: "ButtonMod",
  props: ["state", "mailType", "hasRelatBtn"],
  computed: {
    btnArray() {
      let arr = cloneDeep(this.btnList);
      //无关联应用按钮
      if(!this.hasRelatBtn){
        arr.pop();
      }

      // 垃圾箱
      if (this.mailType === 5) {
        let lis = [
          {
            id: "deleteComplete",
            name: "彻底删除",
            show: true,
            list: []
          },
          {
            id: "move",
            name: "移动到收件箱",
            show: true,
            list: []
          },
          {
            id: "relat",
            name: "关联应用",
            show: true,
            list: []
          }
        ];
        if(!this.hasRelatBtn){
          lis.splice(2,1);
        }
        return lis;
      }

      if (Number(this.state) === 3) { // 已删除
        arr[0].list.splice(0, 1);
      }

      if (this.mailType === 0 || this.mailType === 1) {
        arr.forEach(ele => {
          ele.show = true;
        });
      }else{
         arr.forEach(ele => {
           if (ele.id === "tag"){
            ele.show = false;
           }else{
            ele.show = true;
           }
        });
      }
      return arr;
    }
  },
  components: { buttonItem },
  data() {
    return {
      btnList: [
        {
          id: "delete",
          name: "删除",
          show: true,
          list: [
            {
              id: "delete",
              name: "删除"
            },
            {
              id: "deleteComplete",
              name: "彻底删除"
            }
          ]
        },
        {
          id: "tag",
          name: "标记",
          show: true,
          list: [
            {
              id: "read",
              name: "已读"
            },
            {
              id: "noread",
              name: "未读"
            }
          ]
        },
        {
          id: "relat",
          name: "关联应用",
          show: true,
          list: []
        }
      ]
    };
  },
  methods: {}
};
</script>

<style lang='scss' scoped>
.btn-mod{
  display: flex;
  justify-content: flex-end;

}
</style>
