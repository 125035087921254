var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-box-footer" }, [
    _c(
      "div",
      { staticClass: "md-box-footer-top" },
      [
        _c("en-icon", {
          staticStyle: { color: "#b6c0ce", "margin-right": "4px" },
          attrs: { size: "16", name: "fujianhetupian-kongjian" },
        }),
        _vm._v(
          " 附件" +
            _vm._s(_vm.mailData.attachList.length) +
            "个（" +
            _vm._s(_vm.sum) +
            "KB） "
        ),
        _c("span", { staticClass: "shu" }),
        _c(
          "span",
          {
            staticClass: "down",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.downAll.apply(null, arguments)
              },
            },
          },
          [_vm._v("全部下载")]
        ),
      ],
      1
    ),
    _vm.loading
      ? _c(
          "div",
          { staticClass: "md-box-footer-under" },
          _vm._l(_vm.mailData.attachList, function (item, key) {
            return _c("div", { key: key, staticClass: "enclosure-box" }, [
              _c(
                "div",
                { staticClass: "enclosure-box-left" },
                [
                  item.type === "001"
                    ? _c("en-icon", {
                        staticStyle: {
                          color: "#AFBBC9",
                          "margin-right": "4px",
                          "font-size": "40px",
                        },
                        style: _vm.enclosureIcon(item, "color"),
                        attrs: {
                          size: "40",
                          name: _vm.enclosureIcon(item, "name"),
                        },
                      })
                    : _c("img", {
                        attrs: {
                          height: "40px",
                          width: "40px",
                          src: _vm.down(item),
                          alt: "",
                        },
                      }),
                ],
                1
              ),
              _c("div", { staticClass: "enclosure-box-right" }, [
                _c("div", { staticClass: "enclosure-box-right-name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _c("div", { staticClass: "enclosure-box-right-size" }, [
                  _vm._v(
                    _vm._s((item.attachmentSize / 1024).toFixed(2)) + "KB"
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "btn-down" },
                [
                  _c("en-icon", {
                    staticClass: "btn-down-icon",
                    staticStyle: { color: "#25C393", "font-size": "17px" },
                    attrs: { size: "17", name: "bus-btn18" },
                    nativeOn: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.down(item, "down")
                      },
                    },
                  }),
                ],
                1
              ),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }