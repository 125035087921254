var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "en-dialog",
        {
          staticStyle: { "text-aglin": "left" },
          attrs: {
            top: "8vh",
            visible: _vm.visiable,
            "close-on-click-modal": false,
            width: "1100px",
          },
          on: { close: _vm.closeDialog },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("div", [_vm._v("查看关联建模")]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box" },
            [
              _vm.colums &&
              _vm.colums.length &&
              _vm.tableData &&
              _vm.tableData.records
                ? _c(
                    "en-table",
                    {
                      attrs: {
                        data: _vm.tableData.records,
                        height: 500,
                        "page-config": {
                          pageModel: _vm.tableData,
                          changeMethod: _vm.handlePageChanged,
                        },
                      },
                      on: { "cell-click": _vm.cellClickEvent },
                    },
                    [
                      _c("en-table-column", {
                        attrs: { type: "index", width: "80" },
                      }),
                      _vm._l(_vm.colums, function (item, index) {
                        return _c("en-table-column", {
                          key: index,
                          attrs: {
                            prop: item.id,
                            label: item.name,
                            fieldType: item.colType,
                            data: item,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row, column }) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(row[column.field])),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }