var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "mailDetailEl",
          attrs: {
            id: "mailDetailEl",
            visible: _vm.visible,
            modal: false,
            fullscreen: true,
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "md-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", { staticClass: "md-title-left" }, [
                _vm._v(" " + _vm._s(_vm.mailData.title || "") + " "),
                _vm.mailData.type == 2 && _vm.mailData.status == 1
                  ? _c(
                      "span",
                      {
                        staticClass: "md-title-success",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openRead.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("en-icon", {
                          staticStyle: {
                            color: "#ffffff",
                            "margin-right": "8px",
                          },
                          attrs: { size: "14", name: "duxinliebiao" },
                        }),
                        _vm._v("发送成功 "),
                      ],
                      1
                    )
                  : _vm.mailData.type == 2 &&
                    _vm.mailData.approveStatus &&
                    _vm.mailData.approveStatus != 4
                  ? _c(
                      "span",
                      {
                        staticClass: "md-title-success",
                        style:
                          _vm.approveStatus[_vm.mailData.approveStatus]
                            .background,
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.approveStatus[_vm.mailData.approveStatus].text
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm.mailData.type == 2 &&
                    (_vm.mailData.status == 7 || _vm.mailData.status == 4)
                  ? _c(
                      "span",
                      {
                        staticClass: "md-title-success",
                        staticStyle: { background: "#F76B6A" },
                      },
                      [
                        _c("en-icon", {
                          staticStyle: {
                            color: "#ffffff",
                            "margin-right": "8px",
                          },
                          attrs: { size: "14", name: "duxinliebiao" },
                        }),
                        _vm._v("发送失败 "),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "md-title-right" },
                [
                  _vm.seeflg &&
                  _vm.mailData.status != 6 &&
                  !(_vm.mailData.status == 7 || _vm.mailData.status == 4)
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "md-title-right-icon md-title-right-icon-hover",
                          on: {
                            click: function ($event) {
                              return _vm.reply(1)
                            },
                          },
                        },
                        [
                          _c("en-icon", {
                            staticStyle: {
                              color: "#ffad2c",
                              "margin-right": "5px",
                            },
                            attrs: { size: "18", name: "huifu2" },
                          }),
                          _vm._v(" 回复 "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.seeflg &&
                  _vm.mailData.status != 6 &&
                  !(_vm.mailData.status == 7 || _vm.mailData.status == 4)
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "md-title-right-icon md-title-right-icon-hover",
                          on: {
                            click: function ($event) {
                              return _vm.reply(3)
                            },
                          },
                        },
                        [
                          _c("en-icon", {
                            staticStyle: {
                              color: "#3e90fe",
                              "margin-right": "5px",
                            },
                            attrs: { size: "18", name: "quanbuhuifu" },
                          }),
                          _vm._v(" 全部回复 "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.seeflg &&
                  _vm.mailData.status != 6 &&
                  !(_vm.mailData.status == 7 || _vm.mailData.status == 4)
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "md-title-right-icon md-title-right-icon-hover",
                          on: {
                            click: function ($event) {
                              return _vm.reply(2)
                            },
                          },
                        },
                        [
                          _c("en-icon", {
                            staticStyle: {
                              color: "#26c393",
                              "margin-right": "5px",
                            },
                            attrs: { size: "18", name: "zhuanfa" },
                          }),
                          _vm._v(" 转发 "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.seeflg &&
                  _vm.mailData.status != 6 &&
                  !(_vm.mailData.status == 7 || _vm.mailData.status == 4) &&
                  _vm.mappingList.length
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "md-title-right-icon md-title-right-icon-hover",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openApp.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("en-icon", {
                            staticStyle: {
                              color: "#3e90fe",
                              "margin-right": "5px",
                            },
                            attrs: { size: "16", name: "guanlianyingyong" },
                          }),
                          _vm._v(" 关联应用 "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.seeflg &&
                  _vm.mailData.status == 6 &&
                  _vm.mailData.approveStatus == 3
                    ? _c(
                        "span",
                        {
                          staticClass: "md-title-right-icon",
                          on: {
                            click: function ($event) {
                              return _vm.reply("draft")
                            },
                          },
                        },
                        [
                          _c("en-icon", {
                            staticStyle: {
                              color: "#599EE1",
                              "margin-right": "5px",
                            },
                            attrs: { size: "16", name: "fabu" },
                          }),
                          _vm._v(" 审批发送 "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.seeflg &&
                  (_vm.mailData.status == 7 || _vm.mailData.status == 4)
                    ? _c(
                        "span",
                        {
                          staticClass: "md-title-right-icon",
                          on: { click: _vm.chongxinfasong },
                        },
                        [
                          _c("en-icon", {
                            staticStyle: {
                              color: "#599EE1",
                              "margin-right": "5px",
                            },
                            attrs: { size: "16", name: "fabu" },
                          }),
                          _vm._v(" 重新发送 "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.seeflg &&
                  _vm.mailData.mdDataList &&
                  _vm.mailData.mdDataList.length
                    ? _c(
                        "span",
                        {
                          staticClass: "md-title-right-icon",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openList.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("en-icon", {
                            staticStyle: {
                              color: "#26C293",
                              "margin-right": "5px",
                            },
                            attrs: { size: "16", name: "chakanguanlianjianmo" },
                          }),
                          _vm._v(" 建模详情 "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.seeflg &&
                  _vm.mailData.status != 6 &&
                  !(_vm.mailData.status == 7 || _vm.mailData.status == 4)
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom",
                            width: "160",
                            trigger: "hover",
                            "popper-class": "md-title-el-popover",
                          },
                        },
                        [
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "md-title-el-popover-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.reply(4)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "md-title-right-icon",
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("en-icon", {
                                      staticStyle: {
                                        color: "#3e90fe",
                                        "margin-right": "8px",
                                      },
                                      attrs: {
                                        size: "16",
                                        name: "quanbuhuifudaifujian",
                                      },
                                    }),
                                    _vm._v(" 全部回复（带附件） "),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm.mailData.folderType == 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "md-title-el-popover-item",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.moveMail.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "md-title-right-icon",
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c("en-icon", {
                                          staticStyle: {
                                            color: "#26c393",
                                            "margin-right": "8px",
                                          },
                                          attrs: { size: "16", name: "yidong" },
                                        }),
                                        _vm._v(" 移动到收件箱 "),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.mailData.status != 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "md-title-el-popover-item",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.del(1)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "md-title-right-icon",
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c("en-icon", {
                                          staticStyle: {
                                            color: "#f76b6b",
                                            "margin-right": "8px",
                                          },
                                          attrs: {
                                            size: "16",
                                            name: "shanchu",
                                          },
                                        }),
                                        _vm._v(" 删除 "),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "md-title-el-popover-item",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.del(2)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "md-title-right-icon",
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("en-icon", {
                                      staticStyle: {
                                        color: "#f76b6b",
                                        "margin-right": "8px",
                                      },
                                      attrs: {
                                        size: "16",
                                        name: "chedishanchu",
                                      },
                                    }),
                                    _vm._v(" 彻底删除 "),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "md-title-right-icon md-title-right-icon-hover",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c("en-icon", {
                                staticStyle: {
                                  color: "#a98dd7",
                                  "margin-right": "5px",
                                },
                                attrs: { size: "16", name: "gengduo" },
                              }),
                              _vm._v(" 更多 "),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "md-title-right-icon",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.close.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "close-bg" },
                        [
                          _c("en-icon", {
                            staticStyle: {
                              color: "#F86E6E",
                              "margin-right": "5px",
                              "margin-left": "5px",
                            },
                            attrs: { size: "15", name: "guanbi-danchuang" },
                          }),
                          _vm._v(" 关闭 "),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          !_vm.loading
            ? _c(
                "div",
                { staticClass: "md-box", attrs: { id: "mailDetailEl1" } },
                [
                  _vm.mailData.id
                    ? _c("MailDetailHead", {
                        attrs: { mailData: _vm.mailData },
                        on: { clickenClosure: _vm.clickenClosure },
                      })
                    : _vm._e(),
                  _vm.mailData.id
                    ? _c("div", {
                        staticClass: "md-box-con",
                        staticStyle: { padding: "20px 0px" },
                        domProps: {
                          innerHTML: _vm._s(_vm.mailData.content || ""),
                        },
                      })
                    : _vm._e(),
                  _vm.mailData.attachList && _vm.mailData.attachList.length
                    ? _c("MailDetailFooter", {
                        ref: "enclosure",
                        attrs: { mailData: _vm.mailData, id: "enclosure" },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.slide
            ? _c(
                "div",
                {
                  staticClass: "md-en-slide md-en-slide-left",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.next("pre")
                    },
                    mouseleave: function ($event) {
                      _vm.slide = false
                    },
                  },
                },
                [
                  _c("en-icon", {
                    staticStyle: { color: "#ffffff" },
                    attrs: { size: "18", name: "fanhui" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.slide
            ? _c(
                "div",
                {
                  staticClass: "md-en-slide md-en-slide-right",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.next("next")
                    },
                    mouseleave: function ($event) {
                      _vm.slide = false
                    },
                  },
                },
                [
                  _c("en-icon", {
                    staticStyle: { color: "#ffffff" },
                    attrs: { size: "18", name: "fanhui" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.openList1
        ? _c("ModelList", {
            attrs: { mailData: _vm.mailData, show: _vm.openList1 },
            on: {
              toShow: function ($event) {
                _vm.openList1 = false
              },
            },
          })
        : _vm._e(),
      _vm.addMappingflg
        ? _c("Mapping", {
            attrs: {
              id: _vm.nextId || _vm.id,
              show: _vm.addMappingflg,
              mappingList: _vm.mappingList,
            },
            on: { toShow: _vm.closeMapping },
          })
        : _vm._e(),
      _c("password-dialog", {
        ref: "passwordDialog",
        on: { submit: _vm.submit },
      }),
      _c("receipt-dialog", { ref: "receiptDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }