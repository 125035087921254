var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn-item" },
    [
      _vm.btnData.list.length
        ? _c(
            "el-popover",
            {
              attrs: {
                "popper-class": "btn-item-popover",
                placement: "bottom",
                trigger: "hover",
              },
              model: {
                value: _vm.btnVisible,
                callback: function ($$v) {
                  _vm.btnVisible = $$v
                },
                expression: "btnVisible",
              },
            },
            [
              _c(
                "div",
                { staticClass: "btn-list-wrap" },
                _vm._l(_vm.btnData.list, function (btn) {
                  return _c(
                    "div",
                    {
                      key: btn.id,
                      staticClass: "btn-list-item",
                      on: {
                        click: function ($event) {
                          return _vm.handleBtnClick(btn.id)
                        },
                      },
                    },
                    [
                      _c(
                        "en-button",
                        {
                          attrs: {
                            type: "text",
                            icon: _vm.getBtnIcon(btn.id),
                            iconSize: 16,
                            iconColor:
                              btn.id === "read" ? "#26c393" : "#f76b6b",
                          },
                        },
                        [_vm._v(_vm._s(btn.name))]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "en-button",
                {
                  attrs: {
                    slot: "reference",
                    icon: _vm.getBtnIcon(_vm.btnData.id),
                    iconSize: 16,
                    iconColor: _vm.getBtnIconColor(_vm.btnData.id),
                    type: "text",
                  },
                  slot: "reference",
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.btnData.name))]),
                  _c("i", { staticClass: "iconfont iconfanhui" }),
                ]
              ),
            ],
            1
          )
        : _c(
            "en-button",
            {
              attrs: {
                type: "text",
                icon: _vm.getBtnIcon(_vm.btnData.id),
                iconSize: 16,
                iconColor: _vm.getBtnIconColor(_vm.btnData.id),
              },
              on: {
                click: function ($event) {
                  return _vm.handleBtnClick(_vm.btnData.id)
                },
              },
            },
            [_vm._v(_vm._s(_vm.btnData.name))]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }