<template>
  <div>
    <el-dialog :visible.sync="visible" append-to-body width="500px" class="receipt-dialog">
      <template slot="title">
      </template>
      <div class="container">
        <i class="iconfont">&#xec29;</i>
        <div class="content">
          <h3>{{mailData.senderName}} &lt; {{mailData.senderMail}} &gt; 要求标题为“{{mailData.title}}”发送已读回执，是否发送？</h3>
        </div>
      </div>
      <template slot="footer">
        <div class="footer">
          <el-checkbox v-model="isAlways">以后都执行此操作，不再提示</el-checkbox>
          <div>
            <en-button style="font-size: 12px;" type="white" @click="confirmFn(1)">不发送</en-button>
            <en-button style="font-size: 12px;" @click="confirmFn(0)">发送</en-button>
          </div>
        </div>
      </template>
    </el-dialog>
    <!-- 更换密码弹窗 -->
      <password-dialog ref="passwordDialog"  @submit="submit"></password-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { mailSendService } from "@/api/mailBox/mailSend";
import { mailSettingService } from "@/api/mailBox/mailSetting";
import PasswordDialog from "../../../components/MailSetting/PasswordDialog.vue";

export default {
  name: "ReceiptDialog",
  components: {
    PasswordDialog
  },
  data() {
    return {
      mailData: {},
      visible: false,
      isAlways: true
    };
  },
  computed: {
    ...mapGetters("mailBox", [
      "userMailConfig"
    ])
  },
  methods: {
    ...mapMutations("mailBox", [
      "setUserMailConfig"
    ]),
    show(data) {
      this.mailData = data;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    async submit(data) {
      console.log(data);
      try {
        const res = await mailSettingService.updateMailAccountByGeneral(data);
        console.log(res);
        this.$refs.passwordDialog.hide();
        this.confirmFn(this.sendFlag);
      } catch (error) {
        this.$refs.passwordDialog.btnLoading = false;
      }
    },
    async confirmFn(sendFlag) {
      this.sendFlag = sendFlag;
      this.visible = false;
      const res = await mailSendService.receiptMail({
        sendFlag,
        id: this.mailData.id,
        always: this.isAlways ? 0 : 1
      });
      console.log(res);
      if (res?.length) {
        setTimeout(() => {
          this.$refs.passwordDialog.show(res[0]);
        }, 500);
      }
      if (this.isAlways) {
        this.setUserMailConfig({
          ...this.userMailConfig,
          mailReceiptType: sendFlag === 1 ? "1" : "2"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.receipt-dialog {
  .container {
    display: flex;
    margin-top: 20px;
    .iconfont {
      font-size: 32px;
      color: #3e90fe;
      margin-right: 14px;
    }
    .content {
      h3 {
        color: #333;
        font-size: 14px;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 16px;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
