var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn-mod" },
    _vm._l(_vm.btnArray, function (itm, idx) {
      return _c("button-item", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: itm.show,
            expression: "itm.show",
          },
        ],
        key: idx,
        attrs: { btnData: itm },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }