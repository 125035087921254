<!--
 * @Author: zhangjiaheng
 * @Date: 2021-02-24 10:50:02
 * @LastEditors: zhangjiaheng
 * @LastEditTime: 2021-02-25 15:38:23
 * @Description: 邮件详情 穿透业务建模关联的列表
-->
<template>
  <div>
    <en-dialog top="8vh" :visible="visiable" :close-on-click-modal="false" @close="closeDialog" width="1100px" style="text-aglin:left;">
      <div slot="title">
        <div class="title-box">
          <div>查看关联建模</div>
        </div>
      </div>
      <div class="box">
        <en-table
          @cell-click="cellClickEvent"
          v-if="colums && colums.length && tableData && tableData.records"
          :data="tableData.records"
          :height="500"
          :page-config="{ pageModel: tableData, changeMethod: handlePageChanged }"
        >
          <en-table-column type="index" width="80"></en-table-column>
          <en-table-column v-for="(item, index) in colums" :key="index" :prop="item.id" :label="item.name" :fieldType="item.colType" :data="item">
            <template v-slot="{ row, column }">
              <span>{{ row[column.field] }}</span>
            </template>
          </en-table-column>
        </en-table>
      </div>
    </en-dialog>
  </div>
</template>

<script>
import { readOnlyListEntranceRoute } from "@/components/businessDetail/helper";

export default {
  name: "recordDetails",
  props: {
    // 公共数据
    mailData: {
      type: Object,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visiable: false,
      colums: [
        {
          sort: 0,
          filter: 0,
          fieldType: 1,
          field: "name",
          name: "业务建模名称"
        },
        {
          sort: 0,
          filter: 0,
          fieldType: 1,
          field: "mappingName",
          name: "关联映射名称"
        },
        {
          sort: 0,
          filter: 0,
          fieldType: 1,
          field: "operatorName",
          name: "生成数据操作人"
        },
        {
          sort: 0,
          filter: 0,
          fieldType: 7,
          field: "operatorTime",
          name: "生成时间",
          timeFormat: 9
        }
      ],
      tableData: {
        records: [],
        total: 0,
        totalPages: 0,
        pageNo: 1,
        pageSize: 1
      }
    };
  },
  mounted() {
    this.init();

    this.visiable = this.show;
  },
  methods: {
    // 处理分页数据
    init() {
      this.tableData.records = this.mailData.mdDataList.slice(0, 10);
      this.tableData.total = this.mailData.mdDataList.length;
      // 页数
      this.tableData.pageNo = 1;
      // 一页数量
      this.tableData.pageSize = 10;
      // 计算总页数
      this.tableData.totalPages = Math.ceil(this.tableData.total / 10);
    },
    // 分页事件
    handlePageChanged(obj) {
      console.log(obj);
      // 页数
      this.tableData.records = [];
      this.tableData.pageNo = obj.pageNo;
      // 一页数量
      this.tableData.pageSize = obj.pageSize;
      this.tableData.totalPages = Math.ceil(this.tableData.total / obj.pageSize);
      const max = this.tableData.total > obj.pageNo * obj.pageSize ? obj.pageNo * obj.pageSize : this.tableData.total;
      this.tableData.records = this.mailData.mdDataList.slice((obj.pageNo - 1) * obj.pageSize, max);
    },
    cellClickEvent({ row }) {
      // const url = `/toModelUserComm.do?id=${obj.row.id}&type=${obj.row.templateType}&templateId=${obj.row.templateId}`;
      // window.open(url);
      const route = readOnlyListEntranceRoute({
        id: row.id,
        businessType: row.templateType,
        templateId: row.templateId
      });
      const mailRouter = this.$router.resolve(route);
      window.open(mailRouter.href, "_black");
    },
    // 关闭回调
    closeDialog() {
      this.$emit("toShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  /deep/ table {
    border: 0;
  }
  margin: 0 auto;
  width: 100%;
  min-height: 550px;
  padding: 0 0 17px 0;
  font-size: 12px;
  line-height: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(99, 108, 120, 1);
  .pic-span {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #3e90fe;
  }
}
.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
