var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "relat-temp-alert",
      attrs: { visible: _vm.visible, "append-to-body": "", width: "700px" },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("template", { slot: "title" }, [
        _c(
          "div",
          { staticClass: "left-title" },
          [
            _c("span", { staticClass: "title" }, [_vm._v("关联应用")]),
            _c(
              "el-tooltip",
              {
                attrs: {
                  "popper-class": "relat-temp-tip",
                  placement: "bottom-start",
                },
              },
              [
                _c("template", { slot: "content" }, [
                  _c("div", [
                    _vm._v(
                      '生成状态为"未提交"状态，则生成建模数据，如生成为"未提交"，则需判断，生成的建模数据是否有必填项未填写，如果有则提示"生成失败，有必填项未输入"。'
                    ),
                  ]),
                ]),
                _c("en-icon", {
                  attrs: { name: "tishi", color: "#F7C027", size: "small" },
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "flex-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("关联名称")]),
            _c("en-select", {
              attrs: {
                data: _vm.mappingList,
                "data-mode": "data",
                props: { value: "id", label: "name" },
              },
              model: {
                value: _vm.mappingId,
                callback: function ($$v) {
                  _vm.mappingId = $$v
                },
                expression: "mappingId",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "en-button",
            {
              attrs: { loading: _vm.btnLoading },
              on: { click: _vm.handleRelatClick },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }