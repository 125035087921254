export default {
  data() {
    return {
      // 附件显示的图标
      iconMap: {
        doc: { name: "fujian-Word", color: "color:#3e90fe;" },
        docx: { name: "fujian-Word", color: "color:#3e90fe;" },
        ppt: { name: "fujian-PPT", color: "color:#f76b6b;" },
        pptx: { name: "fujian-PPT", color: "color:#f76b6b;" },
        xls: { name: "fujian-excel", color: "color:#26C393;" },
        xlsx: { name: "fujian-excel", color: "color:#26C393;" },
        zip: { name: "fujian-yasuobao", color: "color:#58cb7e;" },
        rar: { name: "fujian-yasuobao", color: "color:#58cb7e;" },
        "7z": { name: "fujian-yasuobao", color: "color:#58cb7e;" },
        txt: { name: "fujian-txt", color: "color:#3e90fe;" },
        pdf: { name: "fujian-pdf", color: "color:#f76b6b;" }
      },
      other: { name: "fujian-qita", color: "color:##d69e79;" }
    };
  },
  methods: {
    // 附件图标
    enclosureIcon(obj, flg) {
      // "type": "000 图片 001附件 ",
      if (obj.type === "000") {
        return "fujian-tupian";
      }
      // 文件
      const a = obj.extension.toLowerCase();
      if (this.iconMap[a]) {
        return this.iconMap[a][flg];
      }
      return this.other[flg];
    }
  }
};
