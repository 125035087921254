<!--
 * @Author: zhangjiaheng
 * @Date: 2021-02-24 10:50:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-30 11:37:01
 * @Description: 邮件详情 入参 邮件id
-->
<template>
  <div>
    <el-dialog ref="mailDetailEl" id="mailDetailEl" :visible.sync="visible" :modal="false"
      :fullscreen="true" :show-close="false" :close-on-click-modal="false" v-loading="loading">
      <div class="md-title" slot="title">
        <div class="md-title-left">
          {{ mailData.title || "" }}
          <!-- 发件才有的按钮 -->
          <span @click.stop="openRead" v-if="mailData.type == 2 && mailData.status == 1" class="md-title-success">
            <en-icon style="color: #ffffff;margin-right:8px;" size="14" name="duxinliebiao"></en-icon>发送成功
          </span>
          <span v-else-if="mailData.type == 2 && mailData.approveStatus && mailData.approveStatus != 4" :style="approveStatus[mailData.approveStatus].background" class="md-title-success">
            {{ approveStatus[mailData.approveStatus].text }}
          </span>
          <span v-else-if="mailData.type == 2 && (mailData.status == 7 || mailData.status == 4)" style="background: #F76B6A;" class="md-title-success">
            <en-icon style="color: #ffffff;margin-right:8px;" size="14" name="duxinliebiao"></en-icon>发送失败
          </span>
        </div>
        <div class="md-title-right">
          <span v-if="seeflg && mailData.status != 6 && !(mailData.status == 7 || mailData.status == 4)" class="md-title-right-icon md-title-right-icon-hover" @click="reply(1)">
            <en-icon style="color: #ffad2c;margin-right: 5px;" size="18" name="huifu2"></en-icon>
            回复
          </span>
          <span v-if="seeflg && mailData.status != 6 && !(mailData.status == 7 || mailData.status == 4)" class="md-title-right-icon md-title-right-icon-hover" @click="reply(3)">
            <en-icon style="color: #3e90fe;margin-right: 5px;" size="18" name="quanbuhuifu"></en-icon>
            全部回复
          </span>
          <span v-if="seeflg && mailData.status != 6 && !(mailData.status == 7 || mailData.status == 4)" class="md-title-right-icon md-title-right-icon-hover" @click="reply(2)">
            <en-icon style="color: #26c393;margin-right: 5px;" size="18" name="zhuanfa"></en-icon>
            转发
          </span>
          <span
            v-if="seeflg && mailData.status != 6 && !(mailData.status == 7 || mailData.status == 4) && mappingList.length"
            class="md-title-right-icon md-title-right-icon-hover"
            @click.stop="openApp"
          >
            <en-icon style="color: #3e90fe;margin-right: 5px;" size="16" name="guanlianyingyong"></en-icon>
            关联应用
          </span>
          <!-- v2审批发送 审批未通过的邮件  -->
          <span v-if="seeflg && mailData.status == 6 && mailData.approveStatus == 3" class="md-title-right-icon" @click="reply('draft')">
            <en-icon style="color: #599EE1;margin-right: 5px;" size="16" name="fabu"></en-icon>
            审批发送
          </span>
          <!-- v2 发送失败 密码错误的 特殊原因 -->
          <span v-if="seeflg && (mailData.status == 7 || mailData.status == 4)" class="md-title-right-icon" @click="chongxinfasong">
            <en-icon style="color: #599EE1;margin-right: 5px;" size="16" name="fabu"></en-icon>
            重新发送
          </span>
          <!-- v2查看关联建模 -->
          <span v-if="seeflg && mailData.mdDataList && mailData.mdDataList.length" class="md-title-right-icon" @click.stop="openList">
            <en-icon style="color: #26C293;margin-right: 5px;" size="16" name="chakanguanlianjianmo"></en-icon>
            建模详情
          </span>
          <el-popover v-if="seeflg && mailData.status != 6 && !(mailData.status == 7 || mailData.status == 4)" placement="bottom" width="160" trigger="hover" popper-class="md-title-el-popover">
            <div>
              <div class="md-title-el-popover-item" @click="reply(4)">
                <span class="md-title-right-icon" style="display: flex;align-items: center;">
                  <en-icon style="color: #3e90fe;margin-right: 8px;" size="16" name="quanbuhuifudaifujian"></en-icon>
                  全部回复（带附件）
                </span>
              </div>
              <div v-if="mailData.folderType == 5" class="md-title-el-popover-item" @click.stop="moveMail">
                <span class="md-title-right-icon" style="display: flex;align-items: center;">
                  <en-icon style="color: #26c393;margin-right: 8px;" size="16" name="yidong"></en-icon>
                  移动到收件箱
                </span>
              </div>
              <div v-if="mailData.status != 3" class="md-title-el-popover-item" @click.stop="del(1)">
                <span class="md-title-right-icon" style="display: flex;align-items: center;">
                  <en-icon style="color: #f76b6b;margin-right: 8px;" size="16" name="shanchu"></en-icon>
                  删除
                </span>
              </div>
              <div class="md-title-el-popover-item" @click.stop="del(2)">
                <span class="md-title-right-icon" style="display: flex;align-items: center;">
                  <en-icon style="color: #f76b6b;margin-right: 8px;" size="16" name="chedishanchu"></en-icon>
                  彻底删除
                </span>
              </div>
            </div>
            <span class="md-title-right-icon md-title-right-icon-hover" slot="reference">
              <en-icon style="color: #a98dd7;margin-right: 5px;" size="16" name="gengduo"></en-icon>
              更多
            </span>
          </el-popover>
          <span class="md-title-right-icon" @click.stop="close">
            <div class="close-bg">
              <en-icon style="color: #F86E6E;margin-right: 5px;margin-left:5px" size="15" name="guanbi-danchuang"></en-icon>
              关闭
            </div>
          </span>
        </div>
      </div>
      <!-- 内容部分 -->
      <div class="md-box" id="mailDetailEl1" v-if="!loading">
        <!-- 收件人 部分 -->
        <MailDetailHead v-if="mailData.id" :mailData="mailData"  @clickenClosure="clickenClosure"></MailDetailHead>
        <!-- 内容部分 -->
        <div class="md-box-con" v-if="mailData.id" v-html="mailData.content || ''" style="padding:20px 0px"></div>
        <!-- 附件部分 -->
        <MailDetailFooter ref="enclosure" v-if="mailData.attachList && mailData.attachList.length" :mailData="mailData" id="enclosure"></MailDetailFooter>
      </div>
      <div @click.stop="next('pre')" @mouseleave="slide = false" v-if="slide" class="md-en-slide md-en-slide-left ">
        <en-icon style="color: #ffffff;" size="18" name="fanhui"></en-icon>
      </div>
      <div @click.stop="next('next')" @mouseleave="slide = false" v-if="slide" class="md-en-slide md-en-slide-right ">
        <en-icon style="color: #ffffff;" size="18" name="fanhui"></en-icon>
      </div>
    </el-dialog>
    <ModelList v-if="openList1" :mailData="mailData" :show="openList1" @toShow="openList1 = false"></ModelList>
    <Mapping v-if="addMappingflg" :id="nextId || id" :show="addMappingflg" :mappingList="mappingList" @toShow="closeMapping"></Mapping>
    <!-- 更换密码弹窗 -->
    <password-dialog ref="passwordDialog" @submit="submit"></password-dialog>
    <!-- 回执弹窗 -->
    <receipt-dialog ref="receiptDialog"></receipt-dialog>
  </div>
</template>

<script>
import { mailDetail } from "@/api/mailBox/MailDetail/index.js";
import { mailSettingService } from "@/api/mailBox/mailSetting";
import { readOnlyListEntranceRoute } from "@/components/businessDetail/helper";
// import data from "./data.js";
import PasswordDialog from "../../components/MailSetting/PasswordDialog.vue";
import ReceiptDialog from "../List/components/ReceiptDialog";
import MailDetailHead from "./head.vue";
import MailDetailFooter from "./footer.vue";
import ModelList from "./modelList.vue";
import Mapping from "./mapping.vue";

export default {
  name: "MailDetail",
  props: {
    // 邮件的id
    id: {
      type: String,
      required: true,
      default: ""
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    // 所有按钮 是否显示 如果是false 不显示 true显示 默认显示
    seeflg: {
      type: Boolean,
      default: true
    },
    // 为了控制可以查询别人的邮件 和seeflg同理
    businessKey: {
      type: String,
      default: ""
    },
    // 穿透过来的不需要 显示翻译按钮 默认显示
    needNext: {
      type: Boolean,
      default: true
    },
    // 翻页 需要的 数据 当前页码 pageModel 当前查询条件 seParams url
    nextObj: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    MailDetailHead,
    MailDetailFooter,
    ModelList,
    Mapping,
    PasswordDialog,
    ReceiptDialog
  },
  data() {
    return {
      // visible: false,
      mailData: {},
      loading: false,
      // "审批状态，1-待审批，2-审批中，3-未通过，4-通过",
      approveStatus: {
        1: { text: "待审批", background: "background: #3e90fe;" },
        2: { text: "审批中", background: "background: #FFAD2D;" },
        3: { text: "未通过", background: "background: #F76B6A;" },
        4: { text: "通过", background: "display: none;" }
      },
      // 已映射业务建模列表 弹框
      openList1: false,
      // 新增映射 弹框
      addMappingflg: false,
      // 映射规则list
      mappingList: [],
      userId: this.$store.getters.userInfo.userId,
      // 上下翻页 按钮显示
      slide: true,
      // 记录
      remberNum: 0,
      nextId: "",
      // 翻页截流器
      closureFlg: false,
      timer: null
    };
  },
  // 注入父组 list
  inject: ["$mailList"],
  mounted() {
    this.queryMailDetail();
    // 查询映射配置
    this.queryMailBtn();
    // 原生 监听
    document.getElementById("mailDetailEl").onmouseover = this.enter;
    // document.getElementsByTagName("body")[0].onmouseover = this.enter;
    // this.$refs.mailDetailEl.onmouseover = this.enter;
  },
  methods: {
    // 打开弹框
    open() {
      this.visible = true;
    },
    // 打开关联应用
    openApp() {
      // this.$mailList.relatVisible = true;
      if (this.mappingList.length) {
        this.addMappingflg = true;
      } else {
        this.$message({
          message: "无可用的映射配置，请联系管理员",
          type: "warning"
        });
      }
    },
    // 打开业务建模的列表
    openList() {
      // mdDataList的长度为0
      if (this.mailData && this.mailData.mdDataList && this.mailData.mdDataList.length === 1) {
        const route = readOnlyListEntranceRoute({
          id: this.mailData.mdDataList[0].id,
          businessType: this.mailData.mdDataList[0].templateType,
          templateId: this.mailData.mdDataList[0].templateId
        });
        const mailRouter = this.$router.resolve(route);
        window.open(mailRouter.href, "_black");
      } else if (this.mailData && this.mailData.mdDataList && this.mailData.mdDataList.length > 1) {
        this.openList1 = true;
      } else {
        this.$message({
          message: "没有关联的业务建模",
          type: "warning"
        });
      }
    },
    // 删除 1 删除 2 彻底删除
    del(flg) {
      if (flg === 1) {
        this.$mailList.deleteMail(this.nextId || this.id, 1);
      } else {
        this.$mailList.deleteMail(this.nextId || this.id, 2);
      }
    },
    // 关闭弹框
    close() {
      // this.visible = false;
      // 如果只读 关闭的时候需要 跳页面
      if (this.seeflg) {
        this.$emit("close");
      } else {
        window.location.href = "/mailbox";
      }
    },
    // 获取邮件的 详情
    async queryMailDetail() {
      this.loading = true;
      try {
        // 切换邮件id有值 以切换的为主
        const p = { id: this.nextId || this.id };
        if (this.businessKey) {
          p.businessKey = this.businessKey;
        }
        const res = (await mailDetail.queryMailDetail(p)) || "";
        if (res === "") {
          // 如果是空、
          // 提示不存在
          this.$message("该邮件不存在");
          this.loading = false;
          this.close();
          return;
        }
        res.content = this.doneDom(res.content);
        this.mailData = res;
        this.loading = false;
        // 已读标记（0-是，1-否）
        if (this.mailData.readFlag === 1) {
          // 未读
          mailDetail.updateMailInfo({ ids: this.nextId || this.id, readFlag: 0 });
        }
        // v2 如果"receiptFlag": "回执标记（0-是，需要弹窗显示回执，1-否，不需要）",
        // 只读不显示
        // userId 不等于 发件人id
        if (this.seeflg && this.mailData.receiptFlag === 0 && this.userId !== this.mailData.senderUserId) {
          this.receiptHandler(this.mailData);
        }
        // 只有收件
        // "approveStatus": "审批状态，1-待审批，2-审批中，3-未通过，4-通过",
        // "type": "类型（1-收件，2-发件）",
        this.closureFlgfun();
      } catch (error) {
        // this.mailData = data;
        this.loading = false;
        this.closureFlgfun();
      }
    },
    // 发送回执
    receiptHandler(data) {
      this.$refs.receiptDialog.show(data);
    },
    // 打开读写列表
    openRead() {
      // alert("打开读写列表");
    },
    // 回复 邮件 1回复 2转发 3全部回复 4全部恢复带附件
    reply(flg) {
      // 跳转到发件
      const urlObj = {
        path: "/mailBox/mailSend",
        query: {
          mailId: this.nextId || this.id,
          mailType: flg
        }
      };
      this.close();
      this.$router.push(urlObj);
    },
    // 查询映射配置
    async queryMailBtn() {
      this.mappingList = [];
      const list = (await mailDetail.queryMailBtn()) || [];
      list.forEach((element) => {
        if (element.type === 1) {
          this.mappingList.push(element);
        }
      });
    },
    // 重新发送
    async chongxinfasong() {
      const mailData = JSON.parse(JSON.stringify(this.mailData));
      mailData.relatedType = 0;
      mailData.relatedMailId = "";
      mailData.attachList = JSON.stringify(mailData.attachList);
      mailData.ccList = JSON.stringify(mailData.ccList);
      mailData.receiverList = JSON.stringify(mailData.receiverList);
      mailData.operateType = 2;
      // 业务建模 映射到邮件  带上mdDataId, 其他的不能带
      mailData.mdDataId = "";
      const res = await mailDetail.sendMail(mailData);
      // 后面 与发件后保持一致
      if (res.errorMailAccountList?.length) {
        // 密码错误
        this.$refs.passwordDialog.show(res.errorMailAccountList[0]);
        return;
      }

      if (res && res.errorMappingId) {
        // 映射必填项
        // const url = `/toMdModelUserFill.do?templateId=${res.templateId}&mailId=${res.id}&mailBtnId=${res.errorMappingId}&flg=add&type=${res.templateType}`;
        const route = readOnlyListEntranceRoute({
          id: res.id,
          businessType: res.templateType,
          templateId: res.templateId,
          mailBtnId: res.errorMappingId
        });
        const mailRouter = this.$router.resolve(route);
        window.open(mailRouter.href, "_black");
        // window.open(url);
        this.close();
        return;
      }
      this.$message.success("发送成功");
      this.close();
      this.$mailList.queryMailList();
    },
    // 关闭新增映射弹框
    closeMapping(flg) {
      this.addMappingflg = false;
      if (flg) {
        // 需要刷新页面
        this.queryMailDetail();
      }
    },
    // 移动 垃圾箱邮件
    async moveMail() {
      const p = {
        ids: this.nextId || this.id,
        folderType: 1
      };
      await mailDetail.moveMail(p);
      this.$message({
        message: "操作成功",
        type: "success"
      });
      // 刷新列表
      this.$mailList.queryMailList();
      // 关闭弹框
      this.close();
    },
    // 修改密码
    async submit(data) {
      console.log(data);
      try {
        const res = await mailSettingService.updateMailAccountByGeneral(data);
        console.log(res);
        this.$refs.passwordDialog.hide();
        this.chongxinfasong();
      } catch (error) {
        console.log(error);
      }
    },
    // a标签 替换
    doneDom(str) {
      const node = document.createElement("div");
      node.innerHTML = str;
      try {
        this.eachDom(node);
      } catch (error) {
        console.log(error);
      }
      const b = node.innerHTML.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      return b;
    },
    eachDom(node) {
      //  a 标签不处理
      if (node.localName === "a" || node.localName === "code" || node.localName === "img") {
        // a 标签不处理 code 标签不处理 图片 标签不处理
      }
      //  纯文本处理
      if (node.nodeName === "#text") {
        let a = node.textContent;
        a = this.getimgsrc(a);
        node.textContent = a;
        node.data = a;
        node.nodeValue = a;
        // node.wholeText = a;
      }
      // 循环所有的dom子集
      const childNodes = node.childNodes;
      if (childNodes.length === 0) {
        //
      } else {
        childNodes.forEach((element) => {
          this.eachDom(element);
        });
      }
    },
    getimgsrc(content) {
      if (!content) {
        return "";
      }
      const urlPattern = /(https?:\/\/|http?:\/\/)[a-zA-Z_0-9\-@]+(\.\w[a-zA-Z_0-9\-:]+)+(\/[()~#&\-=?+%/.\w]+)?/g;
      content = content.replace(urlPattern, (match) => {
        let href = match;
        if (match.indexOf("http") === -1) {
          href = `http://${match}`;
        }
        return `<a target="_blank" href="${href}" >${match}</a>`;
      });
      return content;
    },
    // 显示 翻页按钮
    enter(e) {
      // e.clientX 水平位置
      if (!this.needNext) {
        return;
      }
      const w = window.innerWidth;
      if (e.clientX < 250 || w - e.clientX < 250) {
        // console.log(true);
        this.slide = true;
      } else {
        // console.log(false);
        this.slide = false;
      }
    },
    nextclick(flg) {
      const timers = this.timer;
      if (timers) {
        window.clearTimeout(timers);
        this.timer = null;
      }
      this.timer = window.setTimeout(() => {
        this.next(flg);
      }, 300);
    },
    nextdblclick(flg) {
      const timers = this.timer;
      if (timers) {
        window.clearTimeout(timers);
        this.timer = null;
      }
      this.next(flg);
    },
    closureFlgfun() {
      setTimeout(() => {
        this.closureFlg = false;
      }, 300);
    },
    async next(flg) {
      if (this.closureFlg) {
        return;
      }
      this.slide = false;
      this.closureFlg = true;
      const no = (this.nextObj.pageModel.pageNo - 1) * this.nextObj.pageModel.pageSize + this.nextObj.index + this.remberNum;
      let next = 0; // 接下来要请求的页码
      if (flg === "pre") {
        // 上一页
        if (no === 1) {
          this.$message("这已经是第一条啦");
          this.closureFlgfun();
          return;
        }
        // 如果是未读列表 这里要做处理 index 被读取后 自动-1
        if (this.nextObj.seParams.unread) {
          this.nextObj.index -= 1;
          next = no === 1 ? 1 : no - 1;
        } else {
          this.remberNum -= 1;
          next = no - 1;
        }
      } else {
        // 下一页
        if (this.nextObj.pageModel.total === no) {
          this.$message("这已经是最后一条啦");
          this.closureFlgfun();
          return;
        }
         // 如果是未读列表 这里要做处理 读取的下一条还是当前条的序号
        if (this.nextObj.seParams.unread) {
          next = no;
        } else {
          this.remberNum += 1;
          next = no + 1;
        }
      }
      // this.nextObj.seParams.unread
      const p = JSON.parse(JSON.stringify(this.nextObj.seParams));
      p.pageSize = 1;
      p.pageNo = next;
      const res = (await mailDetail?.[this.nextObj.url](p)) || [];
      // 取最新的 总条数 可以接收新的
      this.nextObj.pageModel.total = res.total;
      // 切换id
      this.nextId = res.records[0].id;
      this.queryMailDetail();
    },
    // 查看附件 页面滚动
    clickenClosure() {
      window.document.getElementById("enclosure").scrollIntoView();
      // this.$refs.enclosure.$el.scrollIntoView();
    }

  }
};
</script>

<style lang="scss" scoped>
#mailDetailEl {
  z-index: 2000 !important;
}
a {
  color: #3e90fe;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.el-dialog__wrapper /deep/.el-dialog__header {
  height: 50px;
  padding: 0 20px 0 20px;
  display: flex;
  align-items: center;
  width: 100%;
}
.md-title {
  // background: #f3f3f3;
  display: flex;
  justify-content: space-between;
  width: 100%;
  &-left {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    .md-title-success {
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 90px;
      padding: 0 12px;
      height: 26px;
      background: #26c494;
      color: #ffffff;
      font-weight: normal;
      font-size: 12px;
      cursor: pointer;
      margin-left: 12px;
      border-radius: 5px;
    }
  }
  &-right {
    font-weight: normal;
    align-items: center;
    font-size: 12px;
    display: flex;
    &-icon {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 18px;
      font-size: 12px;
      &-hover:hover {
        color: #6fbcf8;
      }
      .close-bg {
        width: 62px;
        display: flex;
        align-items: center;
        background-color: #f76b6b1a;
        text-align: center;
        border-radius: 3px;
        &:hover {
          background-color: #f76b6b26;
        }
      }
    }
  }
}

// 内容外框
.el-dialog__wrapper /deep/.el-dialog__body {
  height: calc(100% - 50px);
  width: 100%;
  padding: 8px;
  background: #ecf1f7;
}
// 上一页下一页按钮
.md-en-slide {
  position: fixed;
  top: 50%;
  z-index: 200;
  margin-top: -23px;
  width: 46px;
  height: 46px;
  opacity: 0.4;
  border-radius: 50%;
  cursor: pointer;
  line-height: 46px;
  font-family: "iconfont" !important;
  font-style: normal;
  font-size: 18px;
  color: #fff;
  background: #a9b5c6;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}
.md-en-slide.md-en-slide-left {
  left: 16px;
}
.md-en-slide.md-en-slide-right {
  right: 16px;
  transform: rotate(180deg);
}
</style>
<style lang="scss">
a {
  color: #3e90fe;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
// md-title-el-popover
.md-title-el-popover {
  width: 100%;
  font-size: 12px;
  padding: 0;
  padding-top: 2px;
  padding-bottom: 2px;
  &-item {
    width: 100%;
    padding-left: 12px;
    height: 36px;
    display: flex;
    color: #636c78;
    font-size: 12px;
    align-items: center;
    cursor: pointer;
    .md-title-right-icon {
      display: inline-block;
      margin: 0;
    }
    &:hover {
      background: #3e90fe;
      color: #ffffff;
      .en-icon {
        color: #ffffff !important;
      }
    }
  }
}
</style>
<style lang="scss">
// 内容
.md-box {
  height: 100%;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  overflow: auto;
  scroll-behavior: smooth; //平滑滚动
  .md-box-con {
    p {
      margin: 10px 0;
      line-height: 1.5;
    }
  }
}
</style>
