<template>
  <div class="md-box-footer">
    <div class="md-box-footer-top">
      <en-icon style="color: #b6c0ce;margin-right:4px;" size="16" name="fujianhetupian-kongjian"></en-icon>
      附件{{ mailData.attachList.length }}个（{{ sum }}KB）
      <span class="shu"></span>
      <span @click.stop="downAll" class="down">全部下载</span>
    </div>
    <div class="md-box-footer-under" v-if="loading">
      <div v-for="(item, key) in mailData.attachList" :key="key" class="enclosure-box">
        <div class="enclosure-box-left">
          <en-icon v-if="item.type === '001'" style="color: #AFBBC9;margin-right:4px;font-size: 40px;" size="40" :style="enclosureIcon(item, 'color')" :name="enclosureIcon(item, 'name')"></en-icon>
          <img v-else height="40px" width="40px" :src="down(item)" alt="" />
        </div>
        <div class="enclosure-box-right">
          <div class="enclosure-box-right-name">{{ item.name }}</div>
          <div class="enclosure-box-right-size">{{ (item.attachmentSize / 1024).toFixed(2) }}KB</div>
        </div>
        <div class="btn-down">
          <en-icon style="color: #25C393;font-size: 17px;" size="17" class="btn-down-icon" name="bus-btn18" @click.stop.native="down(item, 'down')"></en-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mailDetail } from "@/api/mailBox/MailDetail/index.js";
import detail from "./detail.js";

export default {
  name: "MailDetailFooter",
  props: {
    mailData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mixins: [detail],
  data() {
    return {
      // 总大小
      sum: 0,
      token: this.$store.getters.urlParam,
      loading: false
    };
  },
  created() {
    this.queryUpladUrlParam();
  },
  mounted() {
    // 计算文件大小
    this.mailData.attachList.forEach((element) => {
      this.sum += Number(element.attachmentSize);
    });
    this.sum = (this.sum / 1024).toFixed(2);
  },
  methods: {
    // 获取token
    async queryUpladUrlParam() {
      const res = await mailDetail.queryUpladUrlParam();
      this.token = res;
      this.loading = true;
    },
    // 下载
    down(item, flg) {
      const url = this.getFileUrl(item.code, item.type, item.name);
      if (flg === "down") {
        window.open(url);
      }
      return url;
    },
    // 拼接下载地址
    getFileUrl(filecode, type, filename) {
      let fileUrl = ""; // 文件地址
      const name = filename || "";
      const token = this.token;
      if (type === "000") {
        fileUrl = `${token.showImageServer}?token=${token.showImageToken}&ts=${token.imgTs}&fileCode=${filecode}&appKey=${token.appKey}&attachmentName=${name}`;
      } else {
        fileUrl = `${token.getFileServer}?token=${token.getFileToken}&ts=${token.ts}&fileCode=${filecode}&appKey=${token.appKey}&attachmentName=${name}`;
      }
      return fileUrl;
    },
    // 下载全部
    downAll() {
      window.open(`/downloadMailAttach.do?id=${this.mailData.id}`);
      // this.mailData.attachList.forEach((element) => {
      //   const url = this.getFileUrl(element.code, element.type, element.name);
      // });
    }
  }
};
</script>

<style lang="scss" scoped>
.md-box-footer {
  width: 100%;
  height: auto;
  border-top: 1px solid #e8ebf2;
  // 第一层
  .md-box-footer-top {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #636d79;
    .shu {
      display: inline-block;
      height: 12px;
      border-right: 1px solid #a9b4c6;
      width: 0;
      margin: 0 10px;
    }
    .down {
      color: #3e90fe;
      cursor: pointer;
    }
  }
  // 第二层
  .md-box-footer-under {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    .enclosure-box {
      width: 298px;
      height: 58px;
      border: 1px solid #e8ebf2;
      border-radius: 5px;
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 10px 0 0 10px;
      position: relative;
      display: flex;
      background: #fbfbfb;
      .enclosure-box-left {
        width: 40px;
        margin-right: 10px;
      }
      .enclosure-box-right {
        width: calc(100% - 50px);
        .enclosure-box-right-name {
          padding-top: 4px;
          height: 27px;
          font-size: 13px;
          color: #646c79;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .enclosure-box-right-size {
          font-size: 10px;
          color: #99a8bb;
        }
      }
      // 下载
      .btn-down {
        width: 60px;
        height: 56px;
        position: absolute;
        border-radius: 0 5px 5px 0;
        right: 0px;
        top: 0px;
        display: none;
        align-items: center;
        justify-content: center;
        .btn-down-icon {
          cursor: pointer;
        }
      }
    }
    .enclosure-box:hover {
      background: #f4f8fb;
      .btn-down {
        display: flex;
        background: rgba(245, 248, 252, 1) !important;
      }
    }
  }
}
</style>
