import BaseService from "../../BaseService";

class MailDetail extends BaseService {
  constructor(http) {
    super("", http);
  }

  // 查询邮件详情queryMailDetail
  queryMailDetail(params) {
    const { http } = this;
    return http.post("/queryMailDetail", params);
  }

  // 修改邮件的已读未读
  updateMailInfo(params) {
    const { http } = this;
    return http.post("/updateMailInfo", params);
  }

  // token
  queryUpladUrlParam(params) {
    const { http } = this;
    return http.post("/queryUpladUrlParam", params);
  }

  // 查询映射
  queryMailBtn(params) {
    const { http } = this;
    return http.post("/queryMailBtn", params);
  }

  // 执行邮件映射按钮
  executeMailBtn(params) {
    const { http } = this;
    return http.post("/executeMailBtn", params);
  }

  // 发送邮件
  sendMail(params) {
    const { http } = this;
    return http.post("/sendMail", params);
  }

  // 移动邮件
  moveMail(params) {
    const { http } = this;
    return http.post("/moveMail", params);
  }

  // 查邮件列表
  queryMailList(params) {
    const { http } = this;
    return http.post("/queryMailList", params);
  }

  // 搜索邮件列表
  searchMail(params) {
    const { http } = this;
    return http.post("/searchMail", params);
  }
}

export const mailDetail = new MailDetail();
