/*
 * @Author: pengyu
 * @Date: 2021-03-05 15:28:28
 * @LastEditors: pengyu
 * @LastEditTime: 2021-03-17 15:13:32
 * @Description: 邮件列表按钮功能
 */
import { mailBoxService } from "@/api/mailBox/mailList";

export default {
  data() {
    return {
      mappingList: [], // 映射功能
      mappingId: {},
      relatVisible: false // 关联应用选择框
    };
  },
  watch: {
    relatVisible(nVal) {
      if (nVal) {
        this.initMappingList();
      } else {
        this.mappingId = "";
      }
    }
  },
  methods: {
    /**
     * @description 删除邮件
     * @param ids 邮件id，多个逗号隔开
     * @param type 1-删除  2-彻底删除
     */
    async deleteMail(ids, deleteType) {
      const _this = this;
      const h = this.$createElement;
      this.$msgbox({
        title: "",
        closeOnClickModal: false,
        message: h("div", { style: { display: "flex", alignItems: "center", fontSize: "12px" } }, [
          h("en-icon", { attrs: { name: "tishi", color: "#F76B6A" } }),
          h("div", { style: { marginLeft: "10px" } }, [h("p", {}, `确定${deleteType === 1 ? "删除" : "彻底删除"}数据？`), deleteType !== 1 ? h("span", {
            style: { color: "#636c78", marginTop: "8px", display: "inline-block" }
          }, deleteType === 1 ? "" : "彻底删除后，邮件将不能恢复") : ""])
        ]),
        confirmButtonClass: "mail-btn-error",
        async callback(action) {
          if (action === "confirm") {
            await mailBoxService.deleteMailInfo({ ids, deleteType });
            _this.$message({ message: deleteType === 1 ? "删除成功" : "彻底删除成功", type: "success" });
            _this.queryMailList();
            _this.detailVisible = false;
          }
        }
      });
    },
    async moveMail(ids, folderType = 1, noMsg = false) {
      await mailBoxService.moveMail({ ids, folderType });
      !noMsg && this.$message({ message: "操作成功", type: "success" });
      if (this.queryMailTotalNum) {
        this.queryMailTotalNum();
      }
      if (this.seParams?.mailAccount) {
        // 如果是查询的某个邮箱的列表， 还需更新该邮箱的 各箱子数量
        const ml = this.accountList.filter((itm) => itm.mailFullAccount === this.seParams.mailAccount)[0];
        if (ml) {
          this.queryMailBoxCount(ml.id);
        }
      }
      this.queryMailList();
    },
    /**
     * @description 更新邮件已读未读状态
     * @param ids 邮件id，多个逗号隔开
     * @param readFlag 0-是  1-否
     * @param noMsg 不需要提示
     */
    async updateMailRead(ids, readFlag, noMsg = false, isSingle = false) {
      await mailBoxService.updateMailInfo({ ids, readFlag });
      !noMsg && this.$message({ message: "操作成功", type: "success" });
      this.mailList.filter((item) => (isSingle ? item.id === ids : item.checked)).forEach((item) => {
        item.readFlag = readFlag;
      });
      if (this.queryMailTotalNum) {
        this.queryMailTotalNum();
      }
      if (this.seParams?.mailAccount) {
        // 如果是查询的某个邮箱的列表， 还需更新该邮箱的 各箱子数量
        const ml = this.accountList.filter((itm) => itm.mailFullAccount === this.seParams.mailAccount)[0];
        if (ml) {
          this.queryMailBoxCount(ml.id);
        }
      }
      // this.queryMailList();
    },
    /**
     * @description 关联应用确定
     */
     async initMappingList() {
      if (this.mappingList.length) {
        return;
      }
      const rsp = (await mailBoxService.queryMailBtn()) || [];
      rsp.forEach((element) => {
        if (element.type === 1) {
          this.mappingList.push(element);
        }
      });
    },
    /**
     * @description 关联应用确定
     */
    async handleRelatClick(mappingId) {
      this.mappingId = mappingId;
      if (!Object.keys(this.mappingId).length) {
        this.$message("请选择关联名称");
        return;
      }
      this.$refs.mappingDialog.btnLoading = true;
      // 如果detailVisible为true 表示邮件详情的操作 zjh
      const ary = [];
      if (this.relatId) {
        ary.push(this.relatId);
      } else if (this.detailVisible) {
        ary.push(this.detailId);
      } else {
        const checkedData = this.mailList.filter((itm) => itm.checked);
        checkedData.forEach((itm) => {
          ary.push(itm.id);
        });
      }

      await mailBoxService.executeMailBtn({ mailIds: ary.join(","), id: this.mappingId.id });
      this.relatId = null;
      this.$refs.mappingDialog.btnLoading = false;
      this.$message("操作成功");
      this.relatVisible = false;
    }
  }
};
