<template>
  <!-- 关联应用 -->
  <el-dialog :visible.sync="visible" @close="closeDialog" append-to-body width="700px" class="relat-temp-alert">
    <template slot="title">
      <div class="left-title">
        <span class="title">关联应用</span>
        <el-tooltip popper-class="relat-temp-tip" placement="bottom-start">
          <template slot="content">
            <div>生成状态为"未提交"状态，则生成建模数据，如生成为"未提交"，则需判断，生成的建模数据是否有必填项未填写，如果有则提示"生成失败，有必填项未输入"。</div>
          </template>
          <en-icon name="tishi" color="#F7C027" size="small"></en-icon>
        </el-tooltip>
      </div>
    </template>
    <div class="content">
      <div class="flex-item">
        <label for="">关联名称</label>
        <en-select :data="mappingList" v-model="mappingId" data-mode="data" :props="{ value: 'id', label: 'name' }"></en-select>
      </div>
    </div>
    <template slot="footer">
      <en-button :loading="btnLoading" @click="handleRelatClick">确定</en-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: {
    relatVisible: {
      type: Boolean,
      default: false
    },
    mappingList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    relatVisible(newVal) {
      this.visible = newVal;
    }
  },
  data() {
    return {
      mappingId: "",
      visible: false,
      btnLoading: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    handleRelatClick() {
      this.$emit("handleRelatClick", this.mappingId);
    }
  }
};
</script>

<style lang="scss">
// 关联应用弹框
.relat-temp-alert {
  .left-title {
    > span {
      vertical-align: middle;
    }
    .en-icon {
      vertical-align: middle;
      margin-left: 5px;
    }
  }
  .el-dialog__body{
    padding: 20px 20px;
  }
  .el-dialog__footer{
    font-size: 12px;
  }
  .content {
    height: 60px;
    font-size: 12px;
    .flex-item {
      display: flex;
      align-items: center;
      label {
        width: 80px;
      }
    }
  }
  .en-select {
    width: 100%;
  }
}
//关联应用提示
.relat-temp-tip {
  width: 568px;
  &.is-dark {
    background: #676976 !important;
  }
}
</style>
