<template>
  <div class="head-box">
    <!-- 发件人 -->
    <div class="head-box-from">
      <div class="head-box-from-left">
        <el-avatar :size="30" style="background: #FFAD2D;margin-right: 6px;">{{ mailData.senderName[0] }}</el-avatar>
        {{ mailData.senderName || mailData.senderMail || "" }}
      </div>
      <div class="head-box-from-right">
        {{ getDateFormat(mailData.sendTime) }}
      </div>
    </div>
    <!-- 收件人 -->
    <div v-if="mailData.receiverList && mailData.receiverList.length">
      <span class="zi">收件人</span>：
      <div class="zi-right">
        <span v-for="(item, index) in mailData.receiverList || []" :key="index" :data-index="index" class="input-tag">
          <el-popover :content="item.mail" placement="bottom" trigger="hover">
            <span slot="reference">{{ item.name || item.mail }}</span>
          </el-popover>
        </span>
      </div>
    </div>
    <!-- 抄送 -->
    <div v-if="mailData.ccList && mailData.ccList.length">
      <span class="zi">抄送</span>：
      <div class="zi-right">
        <span v-for="(item, index) in mailData.ccList" :key="index" :data-index="index" class="input-tag-cc ">
          <el-popover :content="item.mail" placement="bottom" trigger="hover">
            <span slot="reference">{{ item.name || item.mail }}</span>
          </el-popover>
        </span>
      </div>
    </div>
    <!-- 主题 -->
    <div>
      <span class="zi">主题</span>：

      <span class="head-box-title">{{ mailData.title }}</span>
    </div>
    <!-- 附件 -->
    <div v-if="mailData.attachList && mailData.attachList.length > 0">
      <span class="zi">附件</span>：
      <span class="head-box-enclosure">
        <en-icon style="color: #b6c0ce;margin-right:4px;" size="16" name="fujianhetupian-kongjian"></en-icon>
        共{{ mailData.attachList.length }}个附件（
        <!-- 图片 -->
        <en-icon v-if="mailData.attachList[0].type === '000'" size="17" style="color: #3e90fe;margin:0px 7px" name="fujian-tupian"></en-icon>
        <en-icon v-else :style="enclosureIcon(mailData.attachList[0], 'color')" size="17" style="margin:0px 7px" :name="enclosureIcon(mailData.attachList[0], 'name')"></en-icon>
        {{ mailData.attachList[0].name }}）
        <span @click="clickenClosure" class="head-box-look-enclosure">查看附件</span>
      </span>
    </div>
  </div>
</template>

<script>
import dayJs from "dayjs";
import detail from "./detail.js";

export default {
  name: "MailDetailHead",
  props: {
    mailData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mixins: [detail],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 时间计算
    getDateFormat(dd) {
      if (!dd) {
        return "";
      }
      // 今天 HH:mm、今年：MM-dd、不是今年的：YYYY-MM-DD
      const theDate = dayJs(dd).format("YYYY-MM-DD");
      const todayDate = dayJs().format("YYYY-MM-DD");
      let reDate;
      if (theDate === todayDate) {
        // 今天
        reDate = dayJs(dd).format("HH:mm");
      } else {
        const theYear = new Date(theDate).getFullYear();
        const curYear = new Date(todayDate).getFullYear();
        if (theYear === curYear) {
          // 今年
          reDate = dayJs(dd).format("MM-DD HH:mm");
        } else {
          // 不是今年
          reDate = dayJs(dd).format("YYYY-MM-DD HH:mm");
        }
      }
      return reDate;
    },
    clickenClosure() {
      this.$emit("clickenClosure");
    }
  }
};
</script>

<style lang="scss" scoped>
.zi {
  color: #5072a2;
  display: inline-block;
  width: 50px;
  // width: 5em; /*调整文字间距*/
  text-align-last: justify !important;
}
.head-box {
  width: 100%;
  border-bottom: 1px solid #e8ecf2;
  & > div {
    width: 100%;
    font-size: 12px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    .zi-right {
      width: calc(100% - 50px);
    }
  }
  // 收件人
  .head-box-from {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    margin-bottom: 25px;
    & > div {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #333333;
    }
    .head-box-from-left {
      font-weight: bold;
    }
    .head-box-from-right {
      color: #92a1b4;
    }
  }
  // 主题
  .head-box-title {
    margin-left: 10px;
    font-weight: bold;
    color: #343434;
  }
  // 附件
  .head-box-enclosure {
    display: flex;
    align-items: center;
    .head-box-look-enclosure {
      color: #3e90fe;
      text-decoration: none;
      margin-left: 18px;
      cursor: pointer;
    }
  }
  .input-tag {
    border-radius: 2px;
    display: inline-block;
    font-size: 13px;
    color: #3e90fe;
    margin-right: 6px;
    padding: 5px 8px;
    background: rgba(70, 148, 223, 0.1);
    border-radius: 12px;
    box-sizing: border-box;
    height: 24px;
    line-height: 1;
    cursor: default;
  }
  .input-tag-cc {
    border-radius: 2px;
    display: inline-block;
    font-size: 13px;
    color: rgb(59, 198, 154);
    margin-right: 6px;
    padding: 5px 8px;
    background: rgba(38, 195, 147, 0.1);
    border-radius: 12px;
    box-sizing: border-box;
    height: 24px;
    line-height: 1;
    cursor: default;
    margin-top: 3px;
  }
}
</style>
