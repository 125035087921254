var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "head-box" }, [
    _c("div", { staticClass: "head-box-from" }, [
      _c(
        "div",
        { staticClass: "head-box-from-left" },
        [
          _c(
            "el-avatar",
            {
              staticStyle: { background: "#FFAD2D", "margin-right": "6px" },
              attrs: { size: 30 },
            },
            [_vm._v(_vm._s(_vm.mailData.senderName[0]))]
          ),
          _vm._v(
            " " +
              _vm._s(_vm.mailData.senderName || _vm.mailData.senderMail || "") +
              " "
          ),
        ],
        1
      ),
      _c("div", { staticClass: "head-box-from-right" }, [
        _vm._v(" " + _vm._s(_vm.getDateFormat(_vm.mailData.sendTime)) + " "),
      ]),
    ]),
    _vm.mailData.receiverList && _vm.mailData.receiverList.length
      ? _c("div", [
          _c("span", { staticClass: "zi" }, [_vm._v("收件人")]),
          _vm._v("： "),
          _c(
            "div",
            { staticClass: "zi-right" },
            _vm._l(_vm.mailData.receiverList || [], function (item, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticClass: "input-tag",
                  attrs: { "data-index": index },
                },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        content: item.mail,
                        placement: "bottom",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "reference" }, slot: "reference" },
                        [_vm._v(_vm._s(item.name || item.mail))]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm.mailData.ccList && _vm.mailData.ccList.length
      ? _c("div", [
          _c("span", { staticClass: "zi" }, [_vm._v("抄送")]),
          _vm._v("： "),
          _c(
            "div",
            { staticClass: "zi-right" },
            _vm._l(_vm.mailData.ccList, function (item, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticClass: "input-tag-cc",
                  attrs: { "data-index": index },
                },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        content: item.mail,
                        placement: "bottom",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "reference" }, slot: "reference" },
                        [_vm._v(_vm._s(item.name || item.mail))]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _c("div", [
      _c("span", { staticClass: "zi" }, [_vm._v("主题")]),
      _vm._v("： "),
      _c("span", { staticClass: "head-box-title" }, [
        _vm._v(_vm._s(_vm.mailData.title)),
      ]),
    ]),
    _vm.mailData.attachList && _vm.mailData.attachList.length > 0
      ? _c("div", [
          _c("span", { staticClass: "zi" }, [_vm._v("附件")]),
          _vm._v("： "),
          _c(
            "span",
            { staticClass: "head-box-enclosure" },
            [
              _c("en-icon", {
                staticStyle: { color: "#b6c0ce", "margin-right": "4px" },
                attrs: { size: "16", name: "fujianhetupian-kongjian" },
              }),
              _vm._v(
                " 共" + _vm._s(_vm.mailData.attachList.length) + "个附件（ "
              ),
              _vm.mailData.attachList[0].type === "000"
                ? _c("en-icon", {
                    staticStyle: { color: "#3e90fe", margin: "0px 7px" },
                    attrs: { size: "17", name: "fujian-tupian" },
                  })
                : _c("en-icon", {
                    staticStyle: { margin: "0px 7px" },
                    style: _vm.enclosureIcon(
                      _vm.mailData.attachList[0],
                      "color"
                    ),
                    attrs: {
                      size: "17",
                      name: _vm.enclosureIcon(
                        _vm.mailData.attachList[0],
                        "name"
                      ),
                    },
                  }),
              _vm._v(" " + _vm._s(_vm.mailData.attachList[0].name) + "） "),
              _c(
                "span",
                {
                  staticClass: "head-box-look-enclosure",
                  on: { click: _vm.clickenClosure },
                },
                [_vm._v("查看附件")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }