var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "receipt-dialog",
          attrs: { visible: _vm.visible, "append-to-body": "", width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }),
          _c("div", { staticClass: "container" }, [
            _c("i", { staticClass: "iconfont" }, [_vm._v("")]),
            _c("div", { staticClass: "content" }, [
              _c("h3", [
                _vm._v(
                  _vm._s(_vm.mailData.senderName) +
                    " < " +
                    _vm._s(_vm.mailData.senderMail) +
                    " > 要求标题为“" +
                    _vm._s(_vm.mailData.title) +
                    "”发送已读回执，是否发送？"
                ),
              ]),
            ]),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "el-checkbox",
                  {
                    model: {
                      value: _vm.isAlways,
                      callback: function ($$v) {
                        _vm.isAlways = $$v
                      },
                      expression: "isAlways",
                    },
                  },
                  [_vm._v("以后都执行此操作，不再提示")]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "en-button",
                      {
                        staticStyle: { "font-size": "12px" },
                        attrs: { type: "white" },
                        on: {
                          click: function ($event) {
                            return _vm.confirmFn(1)
                          },
                        },
                      },
                      [_vm._v("不发送")]
                    ),
                    _c(
                      "en-button",
                      {
                        staticStyle: { "font-size": "12px" },
                        on: {
                          click: function ($event) {
                            return _vm.confirmFn(0)
                          },
                        },
                      },
                      [_vm._v("发送")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("password-dialog", {
        ref: "passwordDialog",
        on: { submit: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }